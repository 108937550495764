<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            {{ form.id ? 'Editar Conta' : 'Nova Conta' }}
          </h2>
        </div>
        <div class="modal__form--group">
          <r-select
            label="Banco"
            v-model="form.bank_id"
            :items.sync="banks"
            class="col-12"
            labelName="name"
            placeholder="Selecione o banco"
          />
          <r-input v-model="form.agency" label="Agencia" class="col-12" />
          <r-input v-model="form.number" label="Conta" class="col-12" />
          <r-select
            label="Tipo da conta"
            v-model="form.type"
            :items="[
              { name: 'Conta corrente', id: 1 },
              { name: 'Conta poupança', id: 2 }
            ]"
            class="col-12"
            labelName="name"
            placeholder="Selecionar o tipo da conta"
          />
          <r-input v-model="form.pix" label="PIX" class="col-12" />
          <r-input
            v-model="form.holder"
            label="Nome do titular"
            class="col-12"
          />
          <r-input
            v-model="form.cpf_cnpj"
            :readonly="true"
            label="CPF"
            class="col-12"
          />
          <div class="footer_modal">
            <button type="button" @click="register()" class="button_outline">
              {{ form.id ? 'Atualizar' : 'Cadastrar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import swal from 'sweetalert'
import mask from '../../../utils/mask'

const defaultForm = {
  id: '',
  type: '',
  bank_id: null,
  number: '',
  holder: '',
  pix: '',
  cpf_cnpj: '',
  cpf: ''
}
export default {
  data: () => ({
    form: { ...defaultForm },
    cities: []
  }),

  methods: {
    ...mapActions('bank', { fetchBanks: 'fetch' }),
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    async register () {
      if (!this.valid) {
        return swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos para salvar o registro.',
          icon: 'error',
          button: 'Ok'
        })
      }

      this.loading = true
      try {
        const id = this.form.id
        const url = `financial/storeAccount${id ? '/' + id : ''}`
        const method = id ? 'PUT' : 'POST'
        this.form.user_id = this.user.id
        await axios.request({ method, url, data: this.form })

        swal({
          title: 'Sucesso!',
          text: `Conta ${id ? 'atualizada' : 'cadastrada'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(() => {
          this.form = { ...defaultForm }
          this.$emit('change-modal', false)
          this.$emit('reload-list', true)
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    parseData () {
      const data = new FormData()

      Object.keys(this.form).map(key => {
        const item = this.form[key]
        if (Array.isArray(item)) {
          let count = 0
          item.map(subitem => {
            Object.keys(subitem).map(ik => {
              data.append(`${key}[${count}][${ik}]`, subitem[ik])
            })
            count++
          })
        } else {
          data.append(key, item)
        }
      })
      return data
    }
  },

  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('bank', ['banks']),
    ...mapGetters('user', ['user']),
    valid () {
      const form = this.form
      if (!form.holder) return false
      return true
    }
  },
  props: {
    modal: Boolean,
    selectedAccount: Object
  },
  mounted () {
    this.form.cpf_cnpj = this.user.dados[0].cpf || this.user.dados[0].cnpj
    this.fetchProvinces()
    this.fetchBanks()
  },
  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  watch: {
    selectedAccount: {
      deep: true,
      handler (val) {
        const cpfCnpj = this.user.dados[0].cpf || this.user.dados[0].cnpj

        const fieldMask = val.cpf_cnpj
          ? val.cpf_cnpj.length === 11
            ? '###.###.###-##'
            : '##.###.###/####-##'
          : ''

        this.form = {
          ...val,
          cpf_cnpj: mask(val.cpf_cnpj, fieldMask) || cpfCnpj
        }
      }
    },
    modal (val) {
      const cpfCnpj = this.user.dados[0].cpf || this.user.dados[0].cnpj
      if (!val) {
        setTimeout(
          () => (this.form = { ...defaultForm, cpf_cnpj: cpfCnpj }),
          200
        )
      }
    }
  }
}
</script>

<style></style>
